<template>
  <v-main>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-row
            class="fill-height"
            style="height: 500px"
            align="center"
            justify="center"
          >
            <v-card elevation="0" class="pa-4" width="500px">
              <h2 style="color: grey" class="text-h5 text-center">
                USER LOGIN
              </h2>
              <v-form
                ref="form"
                v-model="valid"
                :lazy-validation="lazy"
                @submit="submit"
              >
                <input-field v-model="form.email" text label="User Name" />
                <input-field
                  v-model="form.password"
                  text
                  type="password"
                  label="Password"
                />
              </v-form>
              <v-card-actions class="px-0">
                <v-btn
                  block
                  depressed
                  tile
                  :loading="isLoading"
                  color="#00cccc"
                  class="white--text mb-3"
                  @click="submit"
                >
                  LOGIN
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import InputField from "@/components/InputField";

export default {
  name: "Login",
  components: { InputField },
  data() {
    return {
      valid: true,
      lazy: false,
      isLoading: false,
      rules: [(value) => !!value || "Kolom ini tidak boleh kosong"],
      form: {},
    };
  },
  created() {
    if (this.$store.getters.isLogged)
      this.$router.push(this.$route.query.redirect || { name: "home" });
  },
  methods: {
    submit() {
      this.$store
        .dispatch("login", this.form)
        .then(() => {
          this.$router.push(this.$route.query.redirect || { name: "home" });
        })
        .catch((err) => {
          console.log(err.message);
          this.$buefy.snackbar.open({
            message: err.message,
            type: "is-danger",
            queue: false,
          });
        });
    },
  },
};
</script>
